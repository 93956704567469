import Vuex from 'vuex'

import app from '../main'
import menu from './modules/menu'
import user from './modules/user'
import country from './modules/country'
import { functions } from '@/mixins/functions.js';

export const store = new Vuex.Store({
	state: {
		
	},
	getters: {
		
	},
	mutations: {
		changeLang (state, payload) {
			// app.$i18n.locale = payload
			localStorage.setItem('currentLanguage', payload);
			functions.methods.req('GET', '/public_controller/language/setSessionLanguage/' + payload);
		}
	},
	actions: {
		setLang ({ commit }, payload) {
			commit('changeLang', payload)
		}
	},
	modules: {
		menu,
		user,
		country
	}
})
