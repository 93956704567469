import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);

// Bootstrap
import 'bootstrap';

// Router & Store add
import router from './router'
// vue-resources
//import VueResource from 'vue-resource'
// vue-moment
//import VueMoment from 'vue-moment'
// store
import { store } from './store'

// vue-i18n
import { createI18n } from 'vue-i18n';
 
import { defaultLocale, localeOptions } from '@/constants/config'
// Notification Component Add
//import Notifications from '@/components/Common/Notification'
// Breadcrumb Component Add
import Breadcrumb from '@/components/Common/Breadcrumb'
// RefreshButton Component Add
import RefreshButton from '@/components/Common/RefreshButton'
// Colxx Component Add
import Colxx from '@/components/Common/Colxx'
// Perfect Scrollbar Add
// import vuePerfectScrollbar from 'vue-perfect-scrollbar'
import contentmenu from 'v-contextmenu'
//import lineClamp from 'vue-line-clamp'
import VCalendar from 'v-calendar'
import VueScrollTo from 'vue-scrollto'
import VueSignature from 'vue-signature-pad';

import mitt from 'mitt'

//v select
import vSelect from 'vue-select'
//SBA toggle
import SbaToggle from '@/components/Content/SbaToggle'

//moment
import moment from 'moment';
// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser, faClock, faEnvelope, faPlus, faPlusCircle, faMinusCircle, faSyncAlt, faSort, faFilter, faSearch, faTable, faPencilAlt, faTrashAlt, faPlusSquare, faTimesCircle, faExclamationCircle, faCheckCircle, faToggleOff, faEye, faEyeSlash, faToggleOn, faThumbsUp, faThumbsDown, faArrowRight, faArrowLeft, faUnlock, faMusic, faUserPlus, faUserTimes, faCheck, faTimes, faPaperPlane, faCopy, faQrcode, faClipboard, faSpinner, faTrash, faSave, faBarcode, faDownload, faCaretDown, faSquareCheck, faStar, faCodeBranch, faArchive, faFile, faChevronLeft, faChevronRight, faMoneyBill, faTicketAlt, faCircle, faSignature, faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { faYoutube, faXTwitter, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'
// swal
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// sba-crud-table
import SbaCrudTable from 'sba-crud-table-v3';
// import SbaCrudTable from '@/components/SbaCrudTable/SbaCrudTable';

import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

import VueMultiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

//jquery
import jQuery from 'jquery'

library.add(faUser, faClock, faEnvelope, faPlus, faPlusCircle, faMinusCircle, faSyncAlt, faSort, faFilter, faSearch, faEye, faEyeSlash, faTable, faPencilAlt, faTrashAlt, faPlusSquare, faTimesCircle, faExclamationCircle, faCheckCircle, faToggleOff, faToggleOn, faThumbsUp, faThumbsDown, faArrowRight, faArrowLeft, faUnlock, faMusic, faUserPlus, faUserTimes, faCheck, faTimes, faPaperPlane, faCopy, faQrcode, faClipboard, faSpinner, faTrash, faSave, faBarcode, faDownload, faCaretDown, faSquareCheck, faStar, faCodeBranch, faArchive, faFile, faChevronRight, faChevronLeft, faMoneyBill, faTicketAlt, faCircle, faSignature, faYoutube, faXTwitter, faInstagram, faFacebook, faShoppingCart)
app.component('font-awesome-icon', FontAwesomeIcon)

//general functionality
import { functions } from '@/mixins/functions.js';

// vue-signature-pad
app.use(VueSignature);



// vue-i18n
//app.use(VueI18n)

// vue-resources
//app.use(VueResource);

// vue-moment
app.use(moment);

// jQuery
app.use(jQuery);

// swal
app.use(VueSweetalert2);

app.use(mitt);

// SbaCrudTable
app.component('SbaCrudTable', SbaCrudTable);
app.component('SbaToggle', SbaToggle);
app.component('v-select', vSelect);
app.component('date-picker', DatePicker);
app.component('multiselect', VueMultiselect);

//var messages = { en: en, nl: nl}
var messages = {}
//var messages = { }
const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale

const i18n = createI18n({
	locale: locale,
	fallbackLocale: 'en',
	messages
});
app.use(i18n)
app.config.globalProperties.$t = i18n.global.t;

// 2. Assign the global variable before mounting
app.config.globalProperties.globalDateFormat = 'YYYY-MM-DD';

//get messages from backend
functions.methods.req('GET', '/public_controller/language/getForTranslation').then((response) => {
	if (response.status) {
		for (let locale in response.data) {
			i18n.global.setLocaleMessage(locale.toLowerCase(), response.data[locale]);
			
			i18n.global.missing = (locale, key) => {
				
				// check for uppercase
				if (i18n.global && /[A-Z]/.test(key)) {
					return i18n.global.t(key.toLowerCase());
				}
			}
		}
	} 
});

//app.use(Notifications)
app.component('piaf-breadcrumb', Breadcrumb)
app.component('b-refresh-button', RefreshButton)
app.component('b-colxx', Colxx)
// app.component('vue-perfect-scrollbar', vuePerfectScrollbar)
app.use(require('vue-shortkey'))
app.use(contentmenu)
//app.use(lineClamp, { /* plugin options */})
app.use(VCalendar, {
	firstDayOfWeek: 2, // ...other defaults,
	formats: {
	title: 'MMM YY',
	weekdays: 'WW',
	navMonths: 'MMMM',
	input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
	dayPopover: 'L'
	},
	datePickerShowDayPopover: false,
	popoverExpanded: true,
	popoverDirection: 'bottom'
})
app.use(VueScrollTo);

app.mixin(functions);

app.use(router);

app.use(store);

app.mount('#app');

// Custom style
let customCss = document.createElement("link");
customCss.setAttribute("rel", "stylesheet");
customCss.setAttribute("type", "text/css");
customCss.setAttribute("href", process.env.VUE_APP_API_URL + '/design/getCss');
document.getElementsByTagName('body')[0].appendChild(customCss);

export default app;
